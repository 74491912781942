import {fetchUserInfo} from '~/fc/Dal/AuthRepo'
import { ConnectionState } from './Constants/connection-state'
export async function getIp () {
  try {
    let { data: { ip } } = await fetchUserInfo()
    return ip
  } catch (e) {
    return null
  }
}

export async function getGeo (ip) {
  const _ip = ip || getIp()
  let geo = await fetch('http://ip-api.com/json/' + _ip)
  geo = (await geo.json())
  return geo
}

export function getConnectionState() {
  try {

      if(!navigator.connection) {
          return ConnectionState.weak;
      }

      const { downlink, rtt } = navigator.connection;

      // Define thresholds
      const weakThreshold = { downlink: 0.5, rtt: 800 }; // Weak: < 0.5 Mbps or RTT > 800ms
      const moderateThreshold = { downlink: 1.5, rtt: 300 }; // Moderate: 0.5–1.5 Mbps or RTT 300–800ms
      // Good: > 1.5 Mbps and RTT < 300ms

      return downlink < weakThreshold.downlink || rtt > weakThreshold.rtt
          ? ConnectionState.weak
          : downlink < moderateThreshold.downlink || rtt > moderateThreshold.rtt
              ? ConnectionState.moderate
              : ConnectionState.good;
  }
  catch {
      return ConnectionState.weak;
  }
}
