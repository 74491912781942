import HttpService from "../HttpService";

export async function signInAdmin(token, data) {
  return await HttpService.account()
    .setPath("/auth/admin-signin")
    .setToken(token)
    .setBody(data)
    .post();
}

export async function signIn(data) {
  return await HttpService.account()
    .setPath("/auth/signin")
    .setBody(data)
    .post();
}

export async function signUp(data) {
  const request = HttpService.account();
  const country = $nuxt.$store.state.store.country;
  if (country && country !== "US")
    request.setHeaders({ "fc-country-code": country });
  return await request.setPath("/auth/signup").setBody(data).post();
}

export async function fetchUserInfo(token) {
  return await HttpService.account().setPath("/me").setToken(token).get();
}

export async function signUpFree(data) {
  return await HttpService.account()
    .setPath("/auth/free-signup-api")
    .setBody(data)
    .post();
}

export async function checkOtp(data, headers) {
  return await HttpService.account()
    .setPath("/auth/check-otp")
    .setHeaders(headers)
    .setBody(data)
    .post();
}

export async function resendOtp(data, headers) {
  return await HttpService.account()
    .setPath("/auth/resend-otp")
    .setHeaders(headers)
    .setBody(data)
    .post();
}

export async function recoverPassword(data) {
  return await HttpService.account()
    .setPath("/auth/forget")
    .setBody(data)
    .post();
}

export async function setPasswordFree(data, headers) {
  return await HttpService.account()
    .setPath("/auth/free-signup")
    .setHeaders(headers)
    .setBody(data)
    .post();
}

export async function resetPassword(data) {
  return await HttpService.account()
    .setPath("/auth/reset-password")
    .setBody(data)
    .post();
}

export async function changePassword(token, data) {
  return await HttpService.account()
    .setPath("/change-password")
    .setToken(token)
    .setBody(data)
    .post();
}

/**
 * Fetches the user's subscription details based on the provided token.
 *
 * @param {string} token - Optional token to retrieve the subscription data.
 * @returns {Promise<object|null>} Resolves with subscription data or null if an error occurs.
 */
export async function getSubscription(token) {
  return new Promise(async (resolve) => {
    try {
      const request = HttpService.process();
      const path = token ? "/user/web-subscription" : "/user/free-subscription";
      const country = $nuxt.$store.state.store.country;
      if (country && country !== "US")
        request.setHeaders({ "fc-country-code": country });
      if (token) request.setToken(token);
      const { data } = await request.setPath(path).get();

      if (data?.plan?.price?.schema === "volume") {
        const { tiers } = data.plan.price;
        const tier = tiers.find(
          (tier) => tier.start * 100 === data.pricingInfo.quantity
        );
        Object.assign(data.plan, {
          processKillTime: tier?.processKillTime || 0,
          maxFileSizeInGb: tier?.maxFileSizeInGb || 0,
          conversionMinutes: data.purchaseMinute,
          isMaxSupportedFile: !(
            data.plan.isMaxSupportedFile &&
            Number(data.plan.maxFileSizeInGb) <
              Number(tiers[tiers.length - 1].maxFileSizeInGb)
          ),
        });
      }

      resolve(data);
    } catch {
      resolve(null);
    }
  });
}

export async function getSubscriptionSettings(subscription) {
  let url = "/settings/";
  if ($nuxt.$auth.loggedIn) {
    if (subscription === "Free") {
      url += "free_member_minutes_per_task";
    } else {
      url += "pro_minutes_per_task";
    }
  } else {
    url += "guest_minutes_per_task";
  }
  return await HttpService.server().setPath(url).get();
}

export async function exchangeAuthorizationCode(code, policyName) {
  const request = HttpService.account();
  const country = $nuxt.$store.state.store.country;
  if (country && country !== "US") {
    request.setHeaders({ "fc-country-code": country });
  }

  return await request
    .setPath(`/auth/exchange-token/?policy_name=${policyName}`)
    .setBody({ code })
    .post();
}
