export const ConnectionState = {
  weak: "weak",
  moderate: "moderate",
  good: "good",
};

export const ChunkSizes = {
  [ConnectionState.weak]: 2 * 1024 * 1024, // 2 MB
  [ConnectionState.moderate]: 5 * 1024 * 1024, // 5 MB
  [ConnectionState.good]: 10 * 1024 * 1024, // 10 MB
};
